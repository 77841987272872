@import "../../../components/theme/index";

.stats {
  &__header {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 60px;
    background-color: $grey;
    color: $darkGrey;
    cursor: pointer;
    padding: 0 10px;
    border: none;
    width: 100%;

    @include respond-to(tablets) {
      padding: 0 50px;
    }

    @include respond-to(mobile) {
      padding: 0 10px;
    }

    // &:focus {
    //   outline: none;
    // }
  }

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    white-space: inherit;
    text-align: left;
    padding-right: 10px;

    @include respond-to(tablets) {
      font-size: 36px;
      white-space: nowrap;
    }
  }

  &__content {
    padding: 34px 10px;

    @include respond-to(mobile) {
      padding: 10px;
    }
    @include respond-to(tablets) {
      padding: 34px 75px;
    }
  }

  &__arrow {
    width: 28px;
    height: 28px;
  }

  &__values {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 23px;
  }

  &__values-group {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 34px;
    margin: 5px 15px;
    min-width: 46%;
    flex: 48% 1;
    font-size: 20px;

    @include respond-to(mobile) {
      padding: 0;
    }
  }

  &__values-group-symptoms {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 34px;
    margin: 5px 15px;
    width: 100%;
    font-size: 20px;

    @include respond-to(mobile) {
      padding: 0;
    }
  }

  &__values-group-questions-text {
    padding: 20px 10px 10px;
    margin: 5px 15px;
    width: 100%;
    font-size: 22px;
    font-weight: bold;

    @include respond-to(mobile) {
      padding: 0;
    }
  }

  &__values-group-name {
    padding: 10px 34px;
    font-size: 24px;
    background-color: $blue;

    @include respond-to(tablets) {
      font-size: 28px;
    }

    @include respond-to(mobile) {
      font-size: 24px;
    }
  }

  &__value {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 34px;
    line-height: 50px;
    margin: 5px 15px;
    font-size: 20px;

    @include respond-to(tablets) {
      font-size: 24px;
    }

    @include respond-to(mobile) {
      font-size: 20px;
    }
  }

  &__value-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
  }

  &__value-number {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ReactCollapse--collapse {
  transition: height 600ms;
}
