$grey: #f2f2f2;
$semiDarkGrey: #bcc7c4;
$blackApprox: #020202;
$pigeonPost: #a3c2d6;
$darkGrey: #303030;
$red: #fc767d;
$darkRed: #f8454e;
$green: #8dd2a3;
$lineGreen: #8ecba0;
$lightGreen: #e9f5eb;
$blue: #a4c7dd;
$yellow: #FFD569;
$alto: #d2d2d2;
$doveGray: #676666;
$lightGray: #707070;
$sirocco: #6f7c7d;
$silver: #c7c7c7;


$white: #fff;
$black: #000;
$accent: #865F7B;
$alternate: #15748C;
$negative: #C0511B;
$positive: #517F70;

$text_secondary: #8A8A8A;