@import "../theme/index";

.gritx-select {
  background-image: url("../../assets/icons/chevron-down-white.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-color: $grey;
  border: 1px solid $alto;
  background-position: 95% center;
  height: 100%;
  display: flex;

  &__form-component {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding-left: 1.5em;
    margin: 0;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5em;
    color: $white;
    @include respond-to(largedesktop) {
      font-size: 20px;
    }
    @include respond-to(fullHD) {
      font-size: 24px;
    }

    & option {
      color: $black;
    }
  }
}
